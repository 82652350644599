<template>
    <div class="file-upload">
        <b-form-group :label="label" description="È possibile caricare solo PNG di grandezza massima di 1MB.">

        <b-form-file
            v-model="file"
            placeholder="Scegli un file o trascinalo qui"
            drop-placeholder="Trascina qui il tuo file..."
        ></b-form-file>
        </b-form-group>

        <img :src="image64" v-if="image64" class="img-fluid" />

        <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
    </div>
</template>

<script>
import UploadDataService from "@/components/upload/UploadDataService";

export default {
    name: "upload-image",
    data() {
        return {
            maxFileSize: 1048576, // in bytes
            acceptedFileType: ['png'],
            file: null,
            uploaded: false,
            image64: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ['label', 'currentImage'],
    methods: {
        uploadFile() {
            const formData = new FormData();
            formData.append("file", this.file);  // appending file
            // sending file to the backend
            UploadDataService.upload(formData)
                .then(response => {
                    this.uploaded = true;
                    this.renderImage(this.file)
                    this.$emit('uploaded-image', response.data);
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        },
        renderImage(data) {
            this.image64 = URL.createObjectURL(data);

        },
        checkFileSize() {
            return this.file.size < this.maxFileSize;
        },
        checkFileType() {
            const filenameInfo = this.file.name.split('.');
            const type = filenameInfo[filenameInfo.length-1];
            return this.acceptedFileType.indexOf(type) > -1;
        }
    },
    watch: {
        file: function (val) {
            this.errorMsg = '';
            // TODO: check if type and size is correct
            if (!this.checkFileSize()) this.errorMsg += "Il file è troppo grande, il massimo consentito è di 2MB!";
            if (!this.checkFileType()) this.errorMsg += "Il file non è un PNG!";
            if (this.errorMsg) return;
            else this.uploadFile();
        },
        currentImage: function (val) {
            this.image64 = 'data:image/png;base64,'+ val;
        }
    },
    mounted() {

    },
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
