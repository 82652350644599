<template>
    <div class="search-bar">
        <div :class="searchClass">
        <span class="search-lens">
            <b-icon icon="search"/>
        </span>
            <input type="text" class="form-control search" :placeholder="(placeholder) ? placeholder : 'Cerca per nome'"
                   autocomplete="off"
                   v-model="name"
                   v-on:keyup.enter="search"
                   v-on:keyup="search"
                   v-on:keyup.esc="reset"/>
            <span class="search-reset" @click="reset" v-if="name">
            <b-icon icon="x"/>
        </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchBar",
    data() {
        return {
            name: '',
            searchClass: 'top'
        }
    },
    props: ["position", "placeholder"],
    methods: {
        reset() {
            this.name = '';
            this.search()
        },
        search() {
            this.$emit('search-name', this.name);
        }
    },
    mounted() {
        if (this.position) {
            switch (this.position) {
                case 'embed':
                    this.searchClass = 'embed'
                    break;
            }
        }
    }
}
</script>

<style scoped>
.search-bar {
    position: relative;
}
.form-control.search {
    border-radius: 1rem;
    border: none;
    padding: 1.28rem 0.75rem;
    padding-left: 40px;
}

.top .form-control.search {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.embed .form-control.search {
    border: 1px solid #eee;
}

.top .form-control.search:focus {
    -webkit-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    -moz-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
}

.search-lens {
    position: absolute;
    top: 9px;
    left: 15px;
    z-index: 2;
}

.search-reset {
    position: absolute;
    top: 9px;
    right: 29px;
    z-index: 2;
}

.embed .search-lens {
    top: 9px;
    left: 15px;
}

.embed .search-reset {
    top: 9px;
    right: 15px;
}

.search-reset:hover {
    cursor: pointer;
}
</style>
