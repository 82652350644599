import http from "../../helpers/http-common";

class UploadDataService {
    upload(data) {
        return http.post("/uploads", data, {headers: {"Content-Type": "application/x-www-form-urlencoded"}});
    }

    get(filename) {
        return http.get(`/uploads/${filename}`);
    }
}

export default new UploadDataService();
